import React, { useEffect, useState } from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import '../NavigationButtons/NavigationButtons.css';

interface ScrollButtonsProps {
  active: boolean;
  isSaving: boolean;
  boardContainer: React.RefObject<HTMLDivElement>;
  totalRows: number;
  boardId: number;
  isScroll: boolean;
  isNavigationButtonsOnTheSide: boolean;
}

const BOTTOM_OFFSET = 7;
const TOP_OFFSET = 5;

const ScrollButtons: React.FC<ScrollButtonsProps> = (props) => {
  const [isScrollTop, setScrollTop] = useState(false);
  const [isScrollDown, setScrollDown] = useState(false);

  useEffect(() => {
    const bc = props.boardContainer.current;

    if (!bc) return; // Verificar si bc es null

    const checkScrollLimits = () => {
      const scrollTop = bc.scrollTop <= TOP_OFFSET;
      const scrollDown =
        Math.round(bc.scrollHeight - bc.scrollTop - bc.clientHeight) - BOTTOM_OFFSET <= 0;
      setScrollTop(scrollTop);
      setScrollDown(scrollDown);
    };

    setTimeout(() => {
      checkScrollLimits();
    }, 1);

    bc.addEventListener('scroll', checkScrollLimits);

    return () => {
      bc.removeEventListener('scroll', checkScrollLimits);
    };
  }, [props.boardId, props.boardContainer, props.totalRows]);

  useEffect(() => {
    const boardContainer = props.boardContainer.current;

    if (!boardContainer) return; // Verificar si boardContainer es null

    if (isScrollTop) {
      boardContainer.scrollBy(0, -TOP_OFFSET);
      return;
    }
    if (isScrollDown) boardContainer.scrollBy(0, BOTTOM_OFFSET);
  }, [isScrollDown, isScrollTop, props.boardContainer]);

  const boardContainer = props.boardContainer.current;

  const step = () => {
    if (!boardContainer) return 0; // Verificar si boardContainer es null

    const isFixedBoard = boardContainer.className.includes('Grid_root');
    const step = boardContainer.scrollHeight / props.totalRows;
    return isScrollDown && !isScrollTop && !isFixedBoard ? step - 7 : step;
  };

  const scrollUp = () => {
    scroll(-step());
  };

  const scrollDown = () => {
    scroll(step());
  };

  const scroll = (step: number) => {
    if (boardContainer) {
      boardContainer.scrollBy(0, step);
    }
  };

  if (!props.active) {
    return null;
  }

  const classScrollUp = props.isNavigationButtonsOnTheSide
    ? `SideNavigationButton SideButtonScrollUp ${
      !props.isScroll || props.isSaving || isScrollTop ? 'disable' : ''
    }`
    : `NavigationButton top`;

  const classScrollDown = props.isNavigationButtonsOnTheSide
    ? `SideNavigationButton SideButtonScrollDown ${
      !props.isScroll || props.isSaving || isScrollDown ? 'disable' : ''
    }`
    : 'NavigationButton bottom';

  return (
    <React.Fragment>
      <div
        className={
        props.isNavigationButtonsOnTheSide
          ? `SideNavigationButtonsContainer ScrollButtons`
          : ''
      }
      >
      {(!isScrollTop || props.isNavigationButtonsOnTheSide) && (
        <div className={classScrollUp}>
        <button onClick={scrollUp}>
          <KeyboardArrowUpIcon />
          </button>
          </div>
      )}
  {(!isScrollDown || props.isNavigationButtonsOnTheSide) && (
    <div className={classScrollDown}>
    <button onClick={scrollDown}>
      <KeyboardArrowDownIcon />
      </button>
      </div>
  )}
  </div>
  </React.Fragment>
);
};

export default ScrollButtons;
