// Define types for your action constants
export const FINISH_FIRST_VISIT: string = 'cboard/App/FINISH_FIRST_VISIT';
export const DISABLE_TOUR: string = 'cboard/App/DISABLE_TOUR';
export const ENABLE_ALL_TOURS: string = 'cboard/App/ENABLE_ALL_TOURS';
export const UPDATE_CONNECTIVITY: string = 'cboard/App/UPDATE_CONNECTIVITY';
export const UPDATE_DISPLAY_SETTINGS: string = 'cboard/App/UPDATE_DISPLAY_SETTINGS';
export const UPDATE_DEVICES_SETTINGS: string = 'cboard/App/UPDATE_DEVICES_SETTINGS';
export const UPDATE_NAVIGATION_SETTINGS: string = 'cboard/App/UPDATE_NAVIGATION_SETTINGS';
export const UPDATE_USER_DATA: string = 'cboard/App/UPDATE_USER_DATA';
export const SET_UNLOGGED_USER_LOCATION: string = 'cboard/App/SET_UNLOGGED_USER_LOCATION';
export const UPDATE_IMPROVED_PHRASE: string = 'cboard/App/UPDATE_IMPROVED_PHRASE';

// Type definition for language codes
type LanguageCode = string;

// Define the default language and available languages
export const DEFAULT_LANG: LanguageCode = 'es-ES';
// export const DEFAULT_LANG: LanguageCode = 'en-US';
export const APP_LANGS: LanguageCode[] = [
  'ar-SA', 'be-BY', 'bn-BD', 'bg-BG', 'cs-CZ', 'da-DK', 'de-DE', 'el-GR',
  'en-US', 'en-GB', 'es-ES', 'fi-FI', 'fr-FR', 'he-IL', 'hi-IN', 'hr-HR',
  'hu-HU', 'id-ID', 'it-IT', 'ja-JP', 'km-KH', 'ko-KR', 'mk-MK', 'ne-NP',
  'nl-NL', 'no-NO', 'pl-PL', 'pt-BR', 'pt-PT', 'ro-RO', 'ru-MD', 'ru-RU',
  'si-LK', 'sk-SK', 'sq-MK', 'sq-AL', 'sr-ME', 'sr-SP', 'sr-RS', 'sv-SE',
  'th-TH', 'tr-TR', 'pt-TL', 'uk-UA', 'vi-VN', 'zh-CN', 'zu-ZA'
];
