import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { getAuthToken } from '../../../api/api';

import Subscribe from './Subscribe.component';
import { getUser, isLogged } from '../../App/App.selectors';
import API from '../../../api';
// import messages from './Subscribe.messages';
import { API_URL } from '../../../constants';

import {
  updateSubscriberId,
  updateSubscription,
  updateSubscriptionError,
  updateIsSubscribed,
  updatePlans
} from '../../../providers/SubscriptionProvider/SubscriptionProvider.actions';

import { formatTitle } from './Subscribe.helpers';

export class SubscribeContainer extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    history: PropTypes.object.isRequired,
    subscription: PropTypes.object.isRequired
  };

  state = {
    cancelSubscriptionStatus: '',
    updatingStatus: true,
    subscriptions: []
  };

  async componentDidMount() {
    const { updateIsSubscribed, updatePlans } = this.props;
    const requestOrigin =
      'Function: componentDidMount - Component: Subscribe Container';
    this.setState({ updatingStatus: true });
    await updateIsSubscribed(requestOrigin);
    this.setState({ updatingStatus: false });
    await this.getSubscriptions();
    updatePlans();
  }

  handleChange = name => event => {
    this.setState({
      ...this.state,
      [name]: event.target.value
    });
  };

  handleRefreshSubscription = () => {
    const { updateIsSubscribed, updatePlans } = this.props;
    const requestOrigin =
      'Fuction: handleRefreshSubscription() - Component: subscribeContainer';
    updateIsSubscribed(requestOrigin);
    updatePlans();
  };

  handleCancelSubscription = async id => {
    const { updateIsSubscribed, updatePlans } = this.props;
    try {
      this.setState({ cancelSubscriptionStatus: 'cancelling' });
      await API.cancelPlan(id);
      setTimeout(() => {
        this.setState({ cancelSubscriptionStatus: 'ok' });
        const requestOrigin =
          'Function: handleCancelSubscription() - Component:Subscribe Container';
        updateIsSubscribed(requestOrigin);
        updatePlans();
        this.getSubscriptions();
      }, 1000);
    } catch (err) {
      console.error(err.message);
      this.setState({ cancelSubscriptionStatus: 'error' });
    }
  };

  getSubscriptions = async () => {
    try {
      const response = await API.getSubscriptions();
      this.setState(ste => ({
        ...ste,
        subscriptions: response.data.suscriptions || []
      }));
    } catch (err) {
      console.error(err.message);
    }
  };

  handleSubscribe = async product => {
    const { user, subscription } = this.props;

    const authToken = getAuthToken();
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`
    };

    try {
      const response = await fetch(API_URL + '/subscription', {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          planId: subscription.products.filter(e => e.provider === 'paypal')[0]
            .planId,
          provider: 'paypal',
          email: user.email
        })
      });

      const data = await response.json();

      if (data.error) {
        alert(data.error.message);
        window.location.reload();
      }
      if (data.link) {
        window.location.replace(data.link);
      }
    } catch (error) {
      console.log(error.message);
      alert(error.message);
    }
  };

  render() {
    const { history, location } = this.props;

    return (
      <Subscribe
        onClose={history.goBack}
        subscriptions={this.state.subscriptions}
        isLogged={this.props.isLogged}
        onSubscribe={this.handleSubscribe}
        location={location}
        subscription={this.props.subscription}
        onRefreshSubscription={this.handleRefreshSubscription}
        onCancelSubscription={this.handleCancelSubscription}
        cancelSubscriptionStatus={this.state.cancelSubscriptionStatus}
        updatingStatus={this.state.updatingStatus}
      />
    );
  }
}

const mapStateToProps = state => {
  const userIsLogged = isLogged(state);
  const user = getUser(state);
  const location = userIsLogged
    ? {
        country: user?.location?.country,
        countryCode: user?.location?.countryCode
      }
    : {
        country: state.app.unloggedUserLocation?.country,
        countryCode: state.app.unloggedUserLocation?.countryCode
      };
  return {
    isLogged: userIsLogged,
    user: user,
    location: location,
    subscription: state.subscription
  };
};

const mapDispatchToProps = {
  updateSubscriberId,
  updateSubscription,
  updateSubscriptionError,
  updateIsSubscribed,
  updatePlans
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(SubscribeContainer));
