import { defineMessages, MessageDescriptor } from 'react-intl';

interface SignUpMessages {
  [key: string]: MessageDescriptor;
}

const messages: SignUpMessages = defineMessages({
  signUp: {
    id: 'cboard.components.SignUp.signUp',
    defaultMessage: 'Sign Up',
  },
  name: {
    id: 'cboard.components.SignUp.name',
    defaultMessage: 'Name',
  },
  email: {
    id: 'cboard.components.SignUp.email',
    defaultMessage: 'Email',
  },
  createYourPassword: {
    id: 'cboard.components.SignUp.createYourPassword',
    defaultMessage: 'Create your password',
  },
  confirmYourPassword: {
    id: 'cboard.components.SignUp.confirmYourPassword',
    defaultMessage: 'Confirm your password',
  },
  cancel: {
    id: 'cboard.components.SignUp.cancel',
    defaultMessage: 'Cancel',
  },
  signMeUp: {
    id: 'cboard.components.SignUp.signMeUp',
    defaultMessage: 'Sign me up',
  },
  agreement: {
    id: 'cboard.components.SignUp.agreement',
    defaultMessage: 'I agree with the {terms} and the {privacy}',
  },
  termsAndConditions: {
    id: 'cboard.components.SignUp.termsAndConditions',
    defaultMessage: 'Terms',
  },
  privacy: {
    id: 'cboard.components.SignUp.privacy',
    defaultMessage: 'Privacy Policy',
  },
});

export default messages;
