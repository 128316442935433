import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { updateDevicesSettings } from '../../App/App.actions';
import Devices from './Devices.component';
import API from '../../../api';

export class DevicesContainer extends PureComponent {
  updateDevicesList = async devicesList => {
    try {
      await API.updateSettings({ devices: devicesList });
      this.props.updateDisplaySettingsAction(devicesList);
    } catch (e) {}
    // this.props.updateDisplaySettingsAction(userData);
  };

  render() {
    const { history, userData } = this.props;

    return (
      <Devices
        {...this.props}
        updateDevicesList={this.updateDevicesList}
        devices={userData.settings.devices}
        onClose={history.goBack}
      />
    );
  }
}

DevicesContainer.props = {
  intl: intlShape.isRequired,
  userData: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  updateDisplaySettings: PropTypes.func
};

const mapStateToProps = ({ app: { userData } }) => ({ userData });

const mapDispatchToProps = {
  updateDisplaySettingsAction: updateDevicesSettings
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(DevicesContainer));
