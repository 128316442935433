export const REQUEST_VOICES = 'cboard/Speech/REQUEST_VOICES' as const;
export const RECEIVE_VOICES = 'cboard/Speech/RECEIVE_VOICES' as const;
export const CHANGE_VOICE = 'cboard/Speech/CHANGE_VOICE' as const;
export const CHANGE_PITCH = 'cboard/Speech/CHANGE_PITCH' as const;
export const CHANGE_RATE = 'cboard/Speech/CHANGE_RATE' as const;
export const CHANGE_VOLUME = 'cboard/Speech/CHANGE_VOLUME' as const;
export const START_SPEECH = 'cboard/Speech/START_SPEECH' as const;
export const END_SPEECH = 'cboard/Speech/END_SPEECH' as const;
export const CANCEL_SPEECH = 'cboard/Speech/CANCEL_SPEECH' as const;
export const RECEIVE_TTS_ENGINES = 'cboard/Speech/RECEIVE_TTS_ENGINES' as const;
export const RECEIVE_TTS_DEFAULT_ENGINE = 'cboard/Speech/RECEIVE_TTS_DEFAULT_ENGINE' as const;
export const REQUEST_TTS_ENGINE = 'cboard/Speech/REQUEST_TTS_ENGINE' as const;
export const RECEIVE_TTS_ENGINE = 'cboard/Speech/RECEIVE_TTS_ENGINE' as const;
export const EMPTY_VOICES = 'empty voices' as const;
