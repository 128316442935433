import { defineMessages, MessageDescriptor } from 'react-intl';

interface ResetPasswordMessages {
  [key: string]: MessageDescriptor;
}

const messages: ResetPasswordMessages = defineMessages({
  email: {
    id: 'cboard.components.ResetPassword.email',
    defaultMessage: 'Email',
  },
  cancel: {
    id: 'cboard.components.ResetPassword.cancel',
    defaultMessage: 'Cancel',
  },
  send: {
    id: 'cboard.components.ResetPassword.send',
    defaultMessage: 'Send',
  },
  resetPassword: {
    id: 'cboard.components.ResetPassword.resetPassword',
    defaultMessage: 'Reset Your Password',
  },
  resetPasswordText: {
    id: 'cboard.components.ResetPassword.resetPasswordText',
    defaultMessage:
      'Enter your email address and we will send you a link to reset your password.',
  },
  resetPasswordSuccess: {
    id: 'cboard.components.ResetPassword.resetPasswordSuccess',
    defaultMessage: 'Success!! Check your mail to reset your password.',
  },
});

export default messages;
