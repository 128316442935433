import {
  UPDATE_SUBSCRIBER_ID,
  UPDATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION_ERROR,
  SHOW_PREMIUM_REQUIRED,
  HIDE_PREMIUM_REQUIRED,
  NOT_SUBSCRIBED,
  REQUIRING_PREMIUM_COUNTRIES,
  ACTIVE,
  CANCELED,
  CANCELLED,
  IN_GRACE_PERIOD,
  EXPIRED,
  PROCCESING,
  SHOW_LOGIN_REQUIRED,
  HIDE_LOGIN_REQUIRED,
  UNVERIFIED,
  DAYS_TO_TRY
} from './SubscriptionProvider.constants';
import API from '../../api';
import { isLogged } from '../../components/App/App.selectors';
import { formatTitle } from '../../components/Settings/Subscribe/Subscribe.helpers';
import { updateNavigationSettings } from '../../components/App/App.actions';
import { getUserData } from '../../api/api';

export function updateIsSubscribed(requestOrigin = 'unkwnown') {
  return async (dispatch, getState) => {
    let isSubscribed = false;
    let ownedProduct = '';
    let status = NOT_SUBSCRIBED;
    let expiryDate = null;
    const state = getState();
    dispatch(
      updateSubscription({
        lastUpdated: new Date().getTime()
      })
    );

    try {
      if (!isLogged(state)) {
        dispatch(
          updateSubscription({
            ownedProduct,
            status,
            isSubscribed,
            expiryDate
          })
        );
      } else {
        const userId = state.app.userData.id;
        const {
          status,
          _id,
          expirationDate,
          createdAt
        } = await API.getSubscriber(userId, requestOrigin);

        isSubscribed =
          status.toLowerCase() === ACTIVE ||
          status.toLowerCase() === CANCELED ||
          status.toLowerCase() === CANCELLED ||
          status.toLowerCase() === IN_GRACE_PERIOD
            ? true
            : false;
        if (isSubscribed) {
          ownedProduct = {
            billingPeriod: 'Mensual',
            id: _id,
            price: '50',
            subscriptionId: 'no viene',
            title: 'Suscripción Premium 1 Mes'
          };
        }

        const BaseDate = new Date(createdAt);
        BaseDate.setDate(BaseDate.getDate() + 10);
        const actualDate = new Date();

        dispatch(
          updateSubscription({
            ownedProduct,
            status: status.toLowerCase(),
            isSubscribed,
            expiryDate,
            expirationDate,
            tryPeriode: createdAt ? BaseDate > actualDate : true
          })
        );
      }
    } catch (err) {
      console.error(getErrorMessage(err));

      isSubscribed = false;
      status = NOT_SUBSCRIBED;
      let ownedProduct = '';
      const isSubscriberNotFound =
        (err.response?.data?.error || err.error) === 'subscriber not found';

      if (isSubscriberNotFound) {
        dispatch(
          updateSubscription({
            ownedProduct,
            status,
            isSubscribed
          })
        );
      }
      //Handle subscription status if is offline
      expiryDate = state.subscription.expiryDate;
      status = state.subscription.status;
      isSubscribed = state.subscription.isSubscribed;

      if (expiryDate && isSubscribed) {
        const expiryDateFormat = new Date(expiryDate);
        const expiryDateMillis = expiryDateFormat.getTime();
        const nowInMillis = Date.now();
        const isExpired = nowInMillis > expiryDateMillis;

        const daysGracePeriod = 7;

        const billingRetryPeriodFinishDate =
          status === ACTIVE
            ? expiryDateFormat.setDate(
                expiryDateFormat.getDate() + daysGracePeriod
              )
            : expiryDateFormat;

        if (isExpired) {
          const isBillingRetryPeriodFinished =
            nowInMillis > billingRetryPeriodFinishDate;

          if (status === CANCELED || isBillingRetryPeriodFinished) {
            dispatch(
              updateSubscription({
                isSubscribed: false,
                status: EXPIRED,
                ownedProduct: ''
              })
            );
            return;
          }
          dispatch(
            updateSubscription({
              isSubscribed: true,
              expiryDate: billingRetryPeriodFinishDate,
              status: IN_GRACE_PERIOD
            })
          );
        }
      }
    }

    return isSubscribed;
  };

  function getErrorMessage(err) {
    return (
      err.message +
      '. ' +
      (err.response ? err.response?.data?.message : err.error)
    );
  }
}

export function updatePlans() {
  return async (dispatch, getState) => {
    try {
      const state = getState();

      const data = await API.listSubscriptions();
      const plans = getActivePlans(data);

      dispatch(
        updateSubscription({
          ...state.subscription,
          products: [...plans]
        })
      );
    } catch (err) {
      console.error(err.message);
    }
  };

  function getActivePlans(subscriptions) {
    let plans = [];

    if (subscriptions.length > 0) {
      subscriptions.forEach(plan => {
        plan.subscriptionName = plan.name;
        plans.push(plan);
      });
    }
    return plans;
  }
}

export function updateSubscriberId(payload = {}) {
  return {
    type: UPDATE_SUBSCRIBER_ID,
    payload
  };
}
export function updateSubscription(payload) {
  const user = getUserData();
  const isByBackOffice = user.isByBackOffice;

  return {
    type: UPDATE_SUBSCRIPTION,
    payload: {
      ...payload,
      status: isByBackOffice ? 'active' : payload?.status,
      isSubscribed: isByBackOffice ? true : payload?.isSubscribed
    }
  };
}
export function updateSubscriptionError(payload) {
  return {
    type: UPDATE_SUBSCRIPTION_ERROR,
    payload
  };
}

export function showPremiumRequired(
  { showTryPeriodFinishedMessages } = { showTryPeriodFinishedMessages: false }
) {
  return {
    type: SHOW_PREMIUM_REQUIRED,
    showTryPeriodFinishedMessages
  };
}

export function hidePremiumRequired() {
  return {
    type: HIDE_PREMIUM_REQUIRED
  };
}

export function showLoginRequired() {
  return {
    type: SHOW_LOGIN_REQUIRED
  };
}

export function hideLoginRequired() {
  return {
    type: HIDE_LOGIN_REQUIRED
  };
}
