import React, { useEffect, useRef } from 'react';
import { initMercadoPago, CardPayment } from '@mercadopago/sdk-react';
import { connect } from 'react-redux';

import FullScreenDialog from '../../components/UI/FullScreenDialog';
import { API_URL } from '../../constants';
import { getAuthToken } from '../../api/api';

const MercadoPagoForm = ({ subscription, user }) => {
  const mp = useRef(null);

  useEffect(() => {
    if (!mp.current) {
      initMercadoPago('APP_USR-5731e924-f390-4d8a-8f5a-e4f6b3286abf');
      mp.current = true;
    }
  }, []);

  const mercadopagoPlan = subscription.products.filter(
    e => e.provider === 'mercadopago'
  )[0];

  const initialization = {
    amount: mercadopagoPlan.price, // Monto de la compra
    payer: {
      email: user.email // Email por defecto
    }
  };

  const customization = {
    visual: {
      style: 'default'
    }
  };

  const goToPay = url => {
    window.location.replace(url);
  };

  const enviarPago = async (cardToken, email) => {
    try {
      const authToken = getAuthToken();
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      };

      const response = await fetch(API_URL + '/subscription', {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          cardTokenId: cardToken,
          planId: mercadopagoPlan.planId,
          provider: 'mercadopago',
          email
        })
      });

      const data = await response.json();
      console.log(data);
      if (data.error) {
        alert(data.error.message || data.message);
        window.location.reload();
      }
      if (data.init_point) {
        setTimeout(() => {
          goToPay('/settings/subscribe');
        }, 2000);
      }
    } catch (error) {
      console.log(error.message);
      alert(error.message);
    }
  };

  const handleGoBack = () => {
    window.location.replace('/settings/subscribe');
  };

  const onSubmit = async formData => {
    await enviarPago(formData.token, formData?.payer?.email);
  };

  const onError = error => {
    console.error('Error en el pago:', error);
    alert(error.message);
  };

  return (
    <FullScreenDialog
      open
      title={'Mercado Pago'}
      onClose={handleGoBack}
      // fullWidth
    >
      <div
        style={{
          width: '100%',
          marginTop: '2rem'
        }}
      >
        <CardPayment
          initialization={initialization}
          customization={customization}
          onSubmit={onSubmit}
          onError={onError}
        />
      </div>
    </FullScreenDialog>
  );
};

const mapStateToProps = state => ({
  subscription: state.subscription,
  user: state.app.userData
});

export default connect(mapStateToProps)(MercadoPagoForm);
