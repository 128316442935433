import * as yup from 'yup';

export interface FormValues {
  password: string;
  passwordConfirm: string;
  name: string;
  email: string;
  isTermsAccepted: boolean;
}

const validationSchema: yup.SchemaOf<FormValues> = yup.object({
  password: yup
    .string()
    .required('Required')
    .oneOf([yup.ref('passwordConfirm'), null], "Passwords don't match"),
  passwordConfirm: yup
    .string()
    .required('Required')
    .oneOf([yup.ref('password'), null], "Passwords don't match"),
  name: yup.string().required('Required'),
  email: yup.string().email('Invalid email').required('Required'),
  isTermsAccepted: yup
    .bool()
    .oneOf([true], 'Accept Terms and Policy is required'),
}) as yup.SchemaOf<FormValues>;

export default validationSchema;
