import React, { FC } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

interface PrivateRouteProps extends RouteProps {
  component: React.ComponentType<any>;
  isLogged: boolean;
  to?: string;
}

interface RedirectIfLoggedProps extends RouteProps {
  component: React.ComponentType<any>;
  isLogged: boolean;
  to?: string;
}

export const PrivateRoute: FC<PrivateRouteProps> = ({
  component: Component,
  isLogged,
  to = '/login-signup',
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      isLogged ? <Component {...props} /> : <Redirect to={to} />
    }
  />
);

export const RedirectIfLogged: FC<RedirectIfLoggedProps> = ({
  component: Component,
  isLogged,
  to = '/',
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      isLogged ? <Redirect to={to} /> : <Component {...props} />
    }
  />
);
export const RedirectIfNeedSubscribe: FC<RedirectIfLoggedProps> = ({
  component: Component,
  isLogged,
  to = '/',
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      isLogged ? <Redirect to={to} /> : <Component {...props} />
    }
  />
);
