import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import moment from 'moment';

import DeleteIcon from '@material-ui/icons/Delete';
import FullScreenDialog from '../../UI/FullScreenDialog';
import messages from './Devices.messages';

import './Devices.css';

const propTypes = {
  /**
   * Callback fired when clicking the back button
   */
  onClose: PropTypes.func,
  displaySettings: PropTypes.object.isRequired,
  updateDevicesList: PropTypes.func.isRequired
};

class Devices extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.displaySettings,
      devices: props.devices
    };
  }

  parseUserAgent(userAgent) {
    let os = 'Desconocido';
    let browser = 'Desconocido';
    let device = 'Desconocido';

    // Detectar sistema operativo
    if (userAgent.includes('Android')) {
      os = userAgent.match(/Android [\d\.]+/)[0];
    } else if (userAgent.includes('Windows')) {
      os = userAgent.match(/Windows NT [\d\.]+/)[0];
    } else if (userAgent.includes('Mac OS X')) {
      os = userAgent.match(/Mac OS X [\d_]+/)[0].replace(/_/g, '.');
    } else if (userAgent.includes('Linux')) {
      os = 'Linux';
    }

    // Detectar navegador
    if (userAgent.includes('Chrome')) {
      browser = userAgent.match(/Chrome\/[\d\.]+/)[0].replace('/', ' ');
    } else if (userAgent.includes('Safari') && userAgent.includes('Version')) {
      browser = userAgent
        .match(/Version\/[\d\.]+/)[0]
        .replace('Version/', 'Safari ');
    } else if (userAgent.includes('Firefox')) {
      browser = userAgent.match(/Firefox\/[\d\.]+/)[0].replace('/', ' ');
    } else if (userAgent.includes('MSIE') || userAgent.includes('Trident')) {
      browser = 'Internet Explorer';
    }

    const deviceMatch = userAgent.match(
      /(Nexus|iPhone|iPad|Galaxy|Pixel|OnePlus|Huawei|Xiaomi|Motorola|Sony|HTC)\s?[\w\d\-]+/
    );
    if (deviceMatch) {
      device = deviceMatch[0];
    } else if (userAgent.includes('Mobile')) {
      device = 'Dispositivo Móvil';
    } else {
      device = 'PC o desconocido';
    }

    return browser + ' | ' + os + ' | ' + device;
  }

  onSubmit() {
    this.props.updateDevicesList(this.state.devices);
  }

  handlerDelete(id) {
    const listFiltered = this.state.devices.filter(e => e._id !== id);

    this.setState({
      devices: listFiltered
    });
  }

  render() {
    const { onClose } = this.props;

    return (
      <FullScreenDialog
        open
        title={<FormattedMessage {...messages.devices} />}
        onClose={onClose}
        onSubmit={this.onSubmit.bind(this)}
      >
        <Paper className="Devices">
          <List>
            {this.state.devices.length > 0 &&
              this.state.devices.map(device => (
                <ListItem>
                  <ListItemText
                    className="Devices__ListItemText"
                    primary={this.parseUserAgent(device.deviceId || '')}
                    secondary={moment(device.lastLogind).format(
                      'DD/MM/YYYY / hh:mm'
                    )}
                  />
                  <ListItemSecondaryAction className="Device__Options">
                    <Button
                      onClick={() => this.handlerDelete(device._id)}
                      variant="contained"
                      color="secondary"
                    >
                      <DeleteIcon />
                    </Button>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}

            <Divider />
          </List>
        </Paper>
      </FullScreenDialog>
    );
  }
}

Devices.propTypes = propTypes;

export default Devices;
